/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-10",
    versionChannel: "nightly",
    buildDate: "2024-03-10T00:05:34.457Z",
    commitHash: "2065661874083687135807b622d5972972684967",
};
